import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        <CustomNavLinkSmall
          style={{
            maxWidth: "250px",
            width: "60%",
            marginBottom: "2.5rem",
            marginRight: "-20px",
            marginLeft: "-20px",
          }}
          onClick={() => scrollTo("contact")}
        >
          <Button
            fixedWidth={false}
            onClick={() =>
              window.open("https://app.locomotive.finance", "_blank")
            }
          >
            <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
              ENTER APP
            </span>
          </Button>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/">
            <img
              src="img/icons/logo192.png"
              style={{
                maxWidth: "150px",
                width: "100%",
                marginTop: "-1.75rem",
                marginLeft: "-30px",
              }}
            />{" "}
            &nbsp; &nbsp;
            <NotHidden style={{ marginTop: "0rem" }}>
              <h6
                style={{
                  marginTop: "1.0rem",
                }}
              >
                Locomotive
              </h6>
            </NotHidden>
          </LogoContainer>
          <MenuItem />
        </Row>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
