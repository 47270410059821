import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <Extra>
        <Container border={true}>
          {/* <Row justify="center" align="middle">
          <ContentWrapper>
            <Col lg={24} md={24} sm={24} xs={24}>
              <h6>{t(title)}</h6>
              <Content>{t(content)}</Content>
            </Col>
          </ContentWrapper>
        </Row> */}
          <Row justify="center" align="middle" style={{ paddingTop: "3rem" }}>
            <FooterContainer>
              <SocialLink
                href="https://twitter.com/LocomotiveDeFi"
                src="twitter.svg"
              />
              <SocialLink
                href="https://discord.com/invite/82RWeAaeBT"
                src="discord.svg"
              />
              <SocialLink
                href="https://t.me/LocomotiveFinance"
                src="telegram.svg"
              />
              <SocialLink
                href="mailto:info@locomotive.finance"
                src="email.svg"
              />
              {/* <SocialLink href="https://github.com" src="github.svg" /> */}
              <SocialLink
                href="https://docs.locomotive.finance/"
                src="docs.svg"
              />
            </FooterContainer>
          </Row>
          <Row justify="center" align="middle" style={{ paddingTop: "3rem" }}>
            {/* <SvgIcon
              src="logo.svg"
              aria-label="homepage"
              width="101px"
              height="64px"
            /> */}
            <img
              src="img/icons/logo192.png"
              width="128px"
              height="128px"
              style={{ marginRight: ".35rem" }}
            />{" "}
            &nbsp; &nbsp;
          </Row>
          <Row
            justify="center"
            align="middle"
            style={{ marginTop: "-1.5rem", color: "#999", fontSize: ".75rem" }}
          >
            <span>LOCOMOTIVE FINANCE</span>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
